@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Nexa-Bold;
  src: url(/static/media/NexaBold.c9f309b3.otf), url(/static/media/NexaBold.6e0085fa.eot);
}

button, label {
  cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
    
    background: #f9f9f9;
    font-family: 'Poppins', arial;
}

div {
    box-sizing: border-box;
}

h1 {
    
    font-weight: 600;
    font-size: 36px;
    color: #BC1224;
    font-family: 'Poppins';
}

h2 {
    
    font-weight: 600;
    font-size: 26px;
    
}

h3 {
    
    font-weight: 700;
    font-size: 24px;
    line-height: 0px;
    
}

p {
    
    color: #454545;
    font-size: 20px;
    font-family: Lato;
    font-weight: normal;
    
    
}




.tutorialWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #BC1224; 
}


.tutorialWrapper .box {
    position:absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    max-width: 1000px;
    max-height: 800px;
    background-color: white;
    box-shadow: 0 2px 13px -1px rgba(0,0,0,0.19);
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    display: block; 
}


.tutorialWrapper .box .phishingStart {
    
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    
}

.startIcon {
    
    color:#BC1224; 
    font-size: 50px;
    
}

.restartButton {
    
    margin-top: 25px;
    width: 250px;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #282c34;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 700;
    font-family: Lato;
    transition-duration: 0.3s;
    cursor: pointer;
      
}


.startButton {
    
    margin-top: 0px;
    width: auto;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    background: #bc1224;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 3px;
    font-size: 22px;
    font-weight: 700;
    font-family: Lato;
    transition-duration: 0.3s;
    cursor: pointer;
      
}

.quizButton {
    
    margin-top: 25px;
    width: 220px;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    background: #BC1224;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 3px;
    font-size: 22px;
    font-weight: 700;
    font-family: Lato;
    transition-duration: 0.3s;
    cursor: pointer;
      
}

.quizButton:hover {
    
    transform: translateY(-5px);
    
}

.nextButton {
    
    width: 150px;
    height: auto;
    padding: 10px 10px 10px 10px;
    content: "Next";
    background: #BC1224;
    color: white;
    font-size: 14px;
    align-self: flex-end;
    text-align: left;
}

.backButton {
    
    width: 150px!important;
    height: auto!important;
    padding: 10px 10px 10px 10px!important;
    content: "Back"!important;
    text-align: right!important;
    background: #454545!important;
    color: white!important;
    font-size: 14px!important;
    align-self: flex-end!important;
}

.stepperHolder {
    
    background: #fafafa;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    
}


.startButton:hover {
    
    transform: translateY(-5px);
    
}


.tutorialWrapper .box .tutorialOne {
    
    
    display: flex;
    flex-direction: column;
    align-content: center;
    max-width: 1000px;
    max-height: 800px;
    height: auto;
    
    
}

.tutorialWrapper .box .tutorialOne p {
   
    text-align: left;  
    
}


.tutorialContent {
    
    padding: 20px 50px 20px 50px;
    
    
}


.flexRow {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.flexRowCenter {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

.tutorialContent .exampleStep {
    
    
    width: 200px;
    height: 500px;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    
    
}

.tutorialContent .exampleStep  p {
    
    
    font-size: 16px;
    
    
}

.tutorialContent .wraps {
    
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    
}

.tutorialContent .wraps .text {

}

.tutorialContent .flexRowCenter span {
    color: #BC1224;
    font-weight: 700;
}

.tutorialContent .wraps span {
    color: #BC1224;
    font-weight: 700;
    font-size: 22px;
}

.tutorialContent .wrapsNote span {
    color: #BC1224;
    font-weight: 700;
    font-size: 30px;
    display: contents;
}

.tutorialContent .wraps .text p {
    
    margin-bottom: 0px;
    
}

li p {
    
    font-weight: 600;
    
}

.phishingImage {
    
    
    width: 300px;
    height:300px;
    max-width: 300px;
    padding-left: 30px;
    
}

.tutorialContent h3 {
    
    line-height: 34px!important;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 20px;
    color: #353535;

}

.tutorialContent h1 {
    
    margin-top: 20px;
    
}

.tutorialContent h2 {
    
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    
}


.rightExampleButton {
    
    
    width: 60px;
    height: 60px;
    color: #353535;

}

.step {
    
   background: white;
    width: 200px;
    height: 150px;;
    border-radius: 6px;
    padding: 20px 20px 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 13px -1px rgba(0,0,0,0.19);

    
}

.circle {
    
   background: white;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    box-shadow: 0 2px 13px -1px rgba(0,0,0,0.19);
}


.companyName {
    
    width: 250px;
    height: auto;
    text-align: center;
    margin-top: 30px;
}

.companyName p {
    
    color: #2e2e2e;
    text-align: center;
    font-size: 16px;
    margin-bottom: -15px;
    padding-left: 15px;
    padding-right: 15px;
}

.companyName h2 {
    
    color: #2e2e2e;
    text-align: center;
    margin-bottom: 40px;
    font-size: 30px;
}



.step p {
    
   text-align: center;
   font-size: 16px;
  
}

.step h3 {
    
    text-align: center;
    font-size: 18px;
    color: #2e2e2e;
    
}

.iconStep2 {
    
    margin-left: 20px;
    margin-right: 20px;
}

.iconExample {
    
    padding-bottom: 20px;
}


.phishingImages {
    
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
    height: 275px;
    
}

.phone {
    
    width: auto;
    height: 275px;
    margin-left: 30px;
    
}

.stepRules {
   
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-width: 4px;
    border-color: #353535;
    border-style: solid;
    margin-bottom: 10px;
    
}

.stepRules h2 {
    
    
    margin-bottom: 28px;
    color: #353535;
    font-size: 18px;
    font-weight: 600;

}

.rules {
   
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-width: 5px;
    border-color: #353535;
    border-style: solid;
    margin-right: 20px;
    
}

.rules h3 {
    
    
    margin-bottom: 0px;
    color: #353535;

}

.textHolder {
    
    display: flex;
    flex-direction: column;
    justify-content: left;
    
}

.textHolder h2{
    
    color: #353535;
    
}

.strong {
    
    font-weight: 600;
    font-size: 18px;

}

.divider {
    
    height: 2px;
    width: 300px;
    background: #CCCCCC;
    margin-bottom: 20px;
    
}

.phishingSignsImage {
    
    width: 900px;
    height: auto;
    margin-bottom: 10px;
    
}

.number-inside{
    font-weight: 900 !important;
}



.slide-5-container{
       display: flex;
       flex-direction: row; 
       width: 100%;
       justify-content: space-around;
}

.slide-5-column h3{
    width: 100%;
}

.slide-5-column{
    min-width: 30%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.slide-5-column .box h3{
    font-size: 16px;
}

.slide-15-circle{
    background: white;
    width: 220px;
    height: 220px;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    box-shadow: 0 2px 13px -1px rgba(0,0,0,0.19);
}
